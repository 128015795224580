import React, { useState, createRef, useEffect } from "react";
import { Redirect, Router } from "@reach/router";
import Home from "./Home";
import { Layout, Col, Row, Spin, Card } from "antd";
const { Header, Content } = Layout;
import { AccountSelector } from "./AccountSelector";
import { LoadingOutlined } from "@ant-design/icons";
import "./styles/App.css";
import { appInsights } from "./utils/Logging";

require("dotenv").config();

import { SubstrateContextProvider, useSubstrate } from "./substrate-lib";
import Contribution from "./Contribution";
import LandingPage from "./LandingPage";
import PreRegistration from "./PreRegistration";
import ReferrerAudit from "./ReferrerAudit";
import TermAndCondition from "./TermAndCondition";
import LandReward from "./LandReward";

appInsights.loadAppInsights();
appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview

function App() {
  const [accountAddress, setAccountAddress] = useState(null);
  // const [loading, setLoading] = useState("loading");
  // const loadingIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;

  // const loader = (text) => (
  //   <Spin className="loader" indicator={loadingIcon} tip={text} />
  // );
  const [currentMenu, setMenu] = useState("home");

  // TODO can't we rely on router to find out the menu items rather than doing it ourselves?
  useEffect(() => {
    const path = location.pathname.slice(1, location.pathname.length);

    // TODO remove all console logs
    console.log(path);

    setMenu(location.pathname === "/" ? "home" : path);
  }, []);

  return (
    <SubstrateContextProvider>
      <Layout>
        <div>
          <div id="stars"></div>
          <div id="stars2"></div>
          <div id="stars3"></div>
          <div id="stars4"></div>
        </div>
        <Row justify="center">
          <Col xs={24} sm={24} md={20} lg={18} xl={18} xxl={16}>
            <AccountSelector
              setAccountAddress={setAccountAddress}
              setLoading={() => {}}
              setMenu={setMenu}
              currentMenu={currentMenu}
              //  default
            />
            <Router primary={false}>
              <LandingPage
                setMenu={setMenu}
                currentMenu={currentMenu}
                path="/"
                default
              ></LandingPage>
              {/* <Home path="/crowdloan"></Home> */}
              {/* <PreRegistration path="/join"></PreRegistration>
              <ReferrerAudit path="/referral-audit"></ReferrerAudit> */}
              <Contribution path="/reward"></Contribution>
              <LandReward path="/land"></LandReward>
              <Contribution path="/contribution"></Contribution>
              <TermAndCondition path="/terms"></TermAndCondition>
              {/* <NotFoun d default /> */}
            </Router>
          </Col>
        </Row>
      </Layout>
    </SubstrateContextProvider>
  );
}

export default App;
