import configCommon from "./common.json";
// Using `require` as `import` does not support dynamic loading (yet).
// TODO this will be hard coded to development or production, development if npm run start and production if npm run build
// the json code will be hard coded into the bundle
const configEnv = require(`./${process.env.NODE_ENV}.json`);

// Accepting React env vars and aggregating them into `config` object.
const envVarNames = [
  "REACT_APP_PROVIDER_SOCKET",
  "REACT_APP_DEVELOPMENT_KEYRING",
];
const envVars = envVarNames.reduce((mem, n) => {
  // Remove the `REACT_APP_` prefix
  if (process.env[n] !== undefined) mem[n.slice(10)] = process.env[n];
  return mem;
}, {});

const config = { ...configCommon, ...configEnv, ...envVars };
export default config;
