import React from "react";
import { Button, Col, Row, Table } from "antd";
import "antd/dist/antd.css";
import { ToastProvider, useToasts } from "react-toast-notifications";

export default function TermAndCondition() {
  return (
    <div>
      <div className="title-header">
        <h2>Bit.Country Pioneer Network Crowdloan Terms and Conditions</h2>
      </div>
      <div>
        <h3>
          By claiming the Bit.Country Pioneer Network Crowdloan Reward, you
          represent and warrant to the BC INNOVATION PTE LTD. ("BC INNOVATION")
          as follows:
        </h3>
      </div>
      <Row>
        <Col span={24} style={{ margin: "auto" }} className="term-list">
          <div>
            <p>
              1. You agree and acknowledge that the Bit.Country Pioneer Network
              Crowdloan Reward is limited to NEER tokens and the grant of NEER
              tokens are subject to the following:
            </p>
            <ul>
              <li className="list-no-style">
                1.1. You will only receive NEER tokens if BC Innovation wins the
                relevant auction. Otherwise, your KSM will be unlocked at the
                end of the campaign.
              </li>
              <li className="list-no-style">
                1.2. If BC Innovation wins the auction, the KSM you contributed
                will remain locked for 48 weeks.
              </li>
              <li className="list-no-style">
                1.3. For every 1 KSM that you agree to lock for 48 weeks, you
                will receive at least 68 NEER tokens on the Bit Country Pioneer
                Network. From time to time, BC Innovation may grant more rewards
                as set out in its medium page. Any rewards above 68 NEER tokens
                are subject to the sole discretion of BC Innovation (&ldquo;
                <strong>Bonus Rewards</strong>&rdquo;).
              </li>
              <li className="list-no-style">
                1.4.Your acceptance of these Terms and Conditions.&nbsp;
              </li>
            </ul>
            <p>
              2. You agree and acknowledge that the Bit.Country Pioneer Network
              Crowdloan leverages on Kusama&rsquo;s built-in crowdloan mechansim
              and the contribution and return of KSM are wholly dependent on
              Kusama Network and BC INNOVATION has no control over any of the
              abovementioned processes.
            </p>
            <p>
              3. You agree and acknowledge that BC INNOVATION has no access to
              the KSM you contributed.&nbsp;
            </p>
            <p>
              4. With regards to the NEER tokens and Bonus Rewards that may be
              granted, you agree and acknowledge that:
            </p>
            <ul>
              <li className="list-no-style">
                4.1. No information from BC INNOVATION constitute a prospectus
                or offer document of any sort nor is intended to constitute an
                offer of securities of any form, units in a business trust,
                units in a collective investment scheme, or any other form of
                capital markets product or investment in any jurisdiction, nor a
                solicitation for any form of investment;
              </li>
              <li className="list-no-style">
                4.2. No regulatory authority has examined or approved the
                information set out by BC INNOVATION and the publication,
                distribution, or dissemination of information by BC INNOVATION
                does not imply to you that the applicable laws, regulatory
                requirements, or rules have been complied with;The NEER tokens
                and Bonus Rewards may not have any functional use,
                merchantability, investment or economic value and therefore you
                shall not be afforded any protections of any kind in relation to
                securities laws or consumer protection laws in any jurisdiction
                around the world.
              </li>
              <li className="list-no-style">
                4.3. Your access to, or use of, and the holding of NEER tokens
                (or any Bonus Rewards) by you is not prohibited or restricted by
                any applicable laws, regulations, or rules in any jurisdiction
                to which you are subject, and where any restrictions are
                applicable, you have observed and complied with all such
                restrictions at your own expense and without liability to BC
                INNOVATION.
              </li>
            </ul>
            <p>
              5. You agree and acknowledge that BC INNOVATION shall not be
              liable for any direct, indirect, special, incidental,
              consequential, or other losses of any kind (including but not
              limited to loss of revenue, income or profits, and loss of use or
              data), in tort (including negligence), contract or otherwise,
              arising out of or in connection with you contributing KSM and/or
              accessing or using the NEER tokens (and any Bonus Rewards).
            </p>
            <p>
              6. You waive the right to participate in a class-action lawsuit or
              a class-wide arbitration against BC INNOVATION, any person
              involved in the BC INNOVATION and/or with the creation and
              distribution of the NEER tokens, Bonus Rewards and/or BitCountry
              Pioneer Network.
            </p>
            <p>
              7. The NEER tokens and Bonus Rewards to be delivered to and
              received by you will not be used for any purpose in connection
              with money laundering, terrorism financing, or any other acts in
              breach or contravention of any applicable law, regulation, or
              rule.
            </p>
            <p>
              8. You bear the sole responsibility to determine what tax
              implications the grant of NEER tokens and/or Bonus Rewards may
              have for you.
            </p>
            <p>
              9. All of the above representations and warranties are and will
              continue to be, true, complete, accurate, and non-misleading from
              the time of your acceptance of this attestation and
              notwithstanding the receipt by you of any NEER tokens and/or Bonus
              Rewards.
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
}
