import { ApplicationInsights } from "@microsoft/applicationinsights-web";

/* eslint-disable no-console */
/* eslint-disable no-undef */
export default {
  TransactionLog: (...args) => {
    if (process.env.NODE_ENV != "production") {
      console.log(...args);
    }

    const [data] = args;

    appInsights.trackEvent(data);
  },
  Log: (...args) => {
    if (process.env.NODE_ENV != "production") {
      console.log(...args);
    }
  },
  Error: (...args) => {
    if (process.env.NODE_ENV != "production") {
      console.error(...args);
    }

    const [error, ...rest] = args;

    appInsights.trackException({ error, data: rest });
  },
};

let appKey = process.env.REACT_APP_INSIGHTS_KEY;

export const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: appKey,
    enableAutoRouteTracking: false,
    autoTrackPageVisitTime: false,
    excludeRequestFromAutoTrackingPatterns: [
      new RegExp(/\/viewer\?x=.+&y=.+&facing=/, "ig"), // Avoid tracking the address bar updater for the world
      // The above doesn't appear to change anything
    ],
    /* ...Other Configuration Options... */
  },
});
