import React, { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { Label } from "semantic-ui-react";
import { Menu, Select, Grid, message, Row, Col } from "antd";
import { Link } from "@reach/router";
import { useSubstrate } from "./substrate-lib";
import SocialLinks from "./SocialLinks";
import Logo from "./assets/images/BCMN-logo-dark-gb.png";
import { utils } from "ethers";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTelegram, faDiscord } from "@fortawesome/free-brands-svg-icons";
import { faSearch, faSlidersH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  MailOutlined,
  AppstoreOutlined,
  SettingOutlined,
  UserOutlined,
  TwitterOutlined,
} from "@ant-design/icons";

export function AccountSelector({ currentMenu, setMenu }) {
  return (
    <>
      <Row style={{ paddingTop: "10px", zIndex: "1" }}>
        <Col flex={4} style={{ paddingTop: "5px", textAlign: "center" }}>
          {" "}
          <div style={{ display: "inline" }}>
            <Link
              className="item"
              to="/"
              onClick={() => {
                setMenu("home");
              }}
            >
              <img className="ui-logo" src={Logo} />
            </Link>
          </div>
        </Col>
        <Col flex={16}>
          {" "}
          <Menu
            onClick={(v) => {
              setMenu(v.key);
            }}
            selectedKeys={currentMenu}
            mode="horizontal"
            disabledOverflow={true}
            style={{
              background: "transparent",
              color: "grey",
              textAlign: "center",
            }}
          >
            <Menu.Item key="home" style={{ color: "white !important" }}>
              <Link to="/" style={{ color: "white !important" }}>
                Home
              </Link>
            </Menu.Item>
            {/* <Menu.Item key="crowdloan">
              <Link to="/crowdloan">Crowdloan</Link>
            </Menu.Item> */}
            {/* <Menu.Item key="referral-audit">
              <Link to="/referral-audit">Referral Code Audit</Link>
            </Menu.Item> */}
            <Menu.Item key="reward">
              <Link to="/reward">Claim NEER Reward</Link>
            </Menu.Item>
            <Menu.Item key="land">
              <Link to="/land">Claim Land Bonus</Link>
            </Menu.Item>
            <Menu.Item key="faq">
              <a
                className="item"
                target="_blank"
                href="https://bitcountry.medium.com/announcement-check-neer-reward-kaosland-metaverse-additional-rewards-f4e0a634039f"
                target="_blank"
              >
                FAQ
              </a>
            </Menu.Item>{" "}
          </Menu>
        </Col>
        <Col flex={4} style={{ textAlign: "center", margin: "auto" }}>
          <div className="social-media-group">
            <a
              href="https://twitter.com/bitdotcountry  "
              target="_blank"
              rel="noopener noreferrer"
            >
              <TwitterOutlined />
            </a>
            <a
              href="https://discord.gg/PaMAXZZ59N"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={["fab", "discord"]} />
            </a>
            <a
              href="https://t.me/BitCountryOfficialTG"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={["fab", "telegram"]} />
            </a>
          </div>
        </Col>
      </Row>
    </>
  );
}

export function BalanceAnnotation(props) {
  const { accountSelected, accountBalance, setAccountBalance } = props;
  const { api } = useSubstrate();
  // const [accountBalance, setAccountBalance] = useState(0);

  // When account address changes, update subscriptions
  // useEffect(() => {
  //   let unsubscribe;

  //   // If the user has selected an address, create a new subscription
  //   accountSelected &&
  //     api.query.system
  //       .account(accountSelected, (balance) => {
  //         if (balance.data.free.toNumber() > 0) {
  //           const balanceInNumber = balance.data.free / Math.pow(10, 12);
  //           setAccountBalance(balanceInNumber);
  //         } else {
  //           setAccountBalance(0);
  //         }
  //       })
  //       .then((unsub) => {
  //         unsubscribe = unsub;
  //       })
  //       .catch(console.error);

  //   return () => unsubscribe && unsubscribe();
  // }, [api, accountSelected]);

  return accountSelected ? <span>{accountBalance} KSM</span> : null;
}
