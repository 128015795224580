import React, { useState, createRef, useEffect } from "react";
import { redirectTo, useLocation, useNavigate } from "@reach/router";
import { useSubstrate } from "./substrate-lib";
import { Button, Col, Row, Space, Table } from "antd";
import "antd/dist/antd.css";
import { ToastProvider, useToasts } from "react-toast-notifications";
import { parse } from "query-string";
import { ArrowDownOutlined } from "@ant-design/icons";
import { fetchAPI } from "./utils/fetchUtil";
import backgroundImageOne from "./assets/images/Continuum3-min.png";
import heroImg from "./assets/images/crowdloan-banner.png";
import { Divider } from "semantic-ui-react";
import polkadotLogo from "./assets/logos/polkadot-js-logo.png";
import BCDarkLogo from "./assets/images/BCMN-logo-dark-gb.png";
import Logging from "./utils/Logging";

export default function LandingPage({ setMenu }) {
  const { api, keyring } = useSubstrate();
  const [accountAddress, setAccountAddress] = useState(null);
  const [contributionData, setContributionData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParam = parse(location.search);
    // console.log("searchParam", searchParam);
    // if (
    //   searchParam["referralCode"] != null &&
    //   searchParam["referralCode"] != ""
    // ) {
    //   setReferralCode(searchParam["referralCode"]);
    // }
  }, []);

  return (
    <ToastProvider autoDismiss>
      <div style={{ textAlign: "center" }}>
        <img src={heroImg} className="hero-img" />
        <Row className="centered-wrapper button-section">
          <Space>
            <Button
              className="primary-button"
              type="primary"
              style={{ width: 200 }}
              onClick={() => {
                navigate("/contribution");
                setMenu("contribution");
              }}
            >
              Check NEER Reward
            </Button>{" "}
            <Button
              className="secondary-button"
              type="primary"
              onClick={() =>
                window.open(
                  "https://bitcountry.medium.com/bit-country-pioneer-crowdloan-on-kusama-2acb5cde9f85",
                  "__blank"
                )
              }
            >
              Learn more
            </Button>
          </Space>
        </Row>
      </div>
      <div className="title-header" style={{ marginTop: "10%" }}>
        <h2>Lock your KSM and receive $NEER Token</h2>
      </div>
      <Row gutter={24} justify="center">
        <Col sm={20} md={12} style={{ margin: "auto" }}>
          <img
            src={backgroundImageOne}
            className="crowdloan-image"
            style={{ width: "100% " }}
          />
        </Col>
        <Col sm={24} md={12}>
          <Row justify="center" gutter={24}>
            <Col xs={20} md={24}>
              <div className="inner-wrapper">
                <h3>1 KSM is minimum 68 $NEER</h3>
                <div>
                  <p>
                    We value the support from our community for backing us to
                    win the parachain slot. If we win the parachain auction, we
                    will reward a minimum of 68 $NEER for 1 KSM.
                  </p>
                </div>
              </div>
            </Col>{" "}
            <Col xs={20} md={24}>
              <div className="inner-wrapper">
                <h3>Early Contribution Bonus</h3>
                <div>
                  <p>
                    For the first 1000 contributors to the Bit.Country Pioneer
                    Network Crowdloan, we will award an extra 10% of the total
                    reward. As always, we value our early adopters who supported
                    us from the beginning.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={20} md={24}>
              <div className="inner-wrapper">
                <h3>Spread the word - earn more rewards</h3>
                <div>
                  <p>
                    If you love what the Bit.Country Pioneer Network brings to
                    the Kusama ecosystem, please spread the word to support our
                    crowdloan. You can share your referral code which appears
                    after your contribution, to share an extra 2.5% reward for
                    you and your referee.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <div className="landing-steps" id="how-to-participate">
        <div className="title-header">
          <h2>How can you participate?</h2>
        </div>{" "}
        <Row justify="center" style={{ margin: "auto" }} gutter={[24, 24]}>
          <Col sm={24} md={12} style={{ textAlign: "center" }}>
            <Row gutter={[24, 24]} justify="center">
              <Col span={24}>
                <img style={{ width: "280px" }} src={polkadotLogo} />
              </Col>
              <Col span={24}>
                <img
                  style={{ width: "280px", paddingTop: "20px" }}
                  src={BCDarkLogo}
                />
              </Col>
            </Row>
          </Col>
          <Col sm={24} md={12}>
            <Col xs={20} sm={24} md={24} style={{ margin: "auto" }}>
              <div>
                <div
                  style={{
                    width: "100%",
                    textAlign: "-webkit-center",
                    display: "flex",
                  }}
                >
                  <p className="landing-step">Step 1</p>
                </div>
                <br />
                <p className="landing-step-content">
                  Buy KSM and set up the{" "}
                  <a href="https://polkadot.js.org/extension" target="__blank">
                    Polkadot{"{.js}"} extension
                  </a>{" "}
                  before you can participate in the Crowdloan.
                </p>
              </div>
            </Col>
            <Col xs={20} sm={24} span={24} style={{ margin: "auto" }}>
              <div>
                <div style={{ width: "100%", textAlign: "-webkit-center" }}>
                  <p className="landing-step">Step 2</p>
                </div>
                <br />
                <p className="landing-step-content">
                  If you have KSM bonded or staked, you will first need to
                  unbond your stake and allow a cooling period of 7 days before
                  you can participate in the Bit.Country Pioneer Network
                  Crowdloan.
                </p>{" "}
              </div>
            </Col>
            <Col xs={20} sm={24} span={24} style={{ margin: "auto" }}>
              <div>
                <div style={{ width: "100%", textAlign: "-webkit-center" }}>
                  <p className="landing-step">Step 3</p>
                </div>
                <br />
                <p className="landing-step-content">
                  Before the Crowdloan begins, we will provide options to
                  contribute here. Watch this space!
                </p>
              </div>
            </Col>
          </Col>
        </Row>
      </div>
      <div>
        <div className="title-header">
          <h2>What is a Crowdloan?</h2>
        </div>
        <Row gutter={24} justify="center">
          <Col xs={20} md={8}>
            <div className="inner-wrapper">
              <h3>Crowdsourced Network Security</h3>
              <div>
                <p>
                  KSM holders lock their tokens on Kusama for 48 weeks to help
                  Bit.Country Pioneer Network lease a parachain slot to gain
                  access to Kusama's shared security and parachain ecosystem. In
                  return for the KSM holders' loan, $NEER (Bit.Country Pioneer
                  Network's native token) will be distributed to participants.
                </p>
              </div>
            </div>
          </Col>

          <Col xs={20} md={8}>
            <div className="inner-wrapper">
              <h3>Community-backed Launch</h3>
              <div>
                <p>
                  Tokens will be returned to holders following the parachain
                  lease, with a guarantee of receiving the KSM principal back.
                  It can be viewed as a community-backed launch for individuals
                  looking to contribute to the evolution of Kusama's network and
                  parachains.
                </p>
              </div>
            </div>
          </Col>
          <Col xs={20} md={8}>
            <div className="inner-wrapper">
              <h3>Fair Network Bootstrapping</h3>
              <div>
                <p>
                  The $NEER distributed to participants will be vested over 12
                  months, however this vested balance can be used to participate
                  in governance and other activities besides transferring. This
                  process helps us build a solid and well-intentioned community
                  from the ground up.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </ToastProvider>
  );
}
