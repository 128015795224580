export const chainType = "Ethereum" | "Substrate";
export const bridgeConfig = {
  chains: [
    {
      chainId: 0,
      networkId: 1,
      name: "Ethereum - Rinkeby",
      decimals: 18,
      bridgeAddress: "0x3c13771793531cEE5EE99853003b6aEA632a828B",
      erc20HandlerAddress: "0x454A814c7E9A8368d03c358A7e4F3E8743e0E371",
      rpcUrl: "wss://rinkeby-light.eth.linkpool.io",
      type: "Ethereum",
      nativeTokenSymbol: "ETH",
      defaultGasPrice: 2,
      tokens: [
        {
          address: "0x039d8b8CA8DD19aa187a693C188e7FFb3d0027e8",
          name: "ENUUM",
          symbol: "ENUUM",
          imageUri: "noimage.png",
          resourceId:
            "0x000000000000000000000000000000c76ebe4a02bbc34786d860b355f5a5ce00",
        },
      ],
    },
    {
      chainId: 1,
      networkId: 2,
      name: "Substrate - Local",
      decimals: 12,
      rpcUrl: "wss://kusama-rpc.polkadot.io/",
      type: "Substrate",
      nativeTokenSymbol: "KSM",
      chainbridgePalletName: "chainBridge",
      bridgeFeeFunctionName: "tokenTransferFee",
      transferPalletName: "bridgeTransfer",
      transferFunctionName: "transferNative",
      typesFileName: "types.json",
      tokens: [
        {
          address: "substrate-native",
          name: "NUUM",
          symbol: "NUUM",
          resourceId: "substrate-native",
        },
      ],
    },
  ],
};
