import React, { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { Menu, Select, Grid, Row, Col, Modal, Tooltip, Button } from "antd";
import {
  CheckOutlined,
  InfoCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Link } from "@reach/router";
import { useSubstrate } from "../substrate-lib";
import SocialLinks from "../SocialLinks";
import Logo from "./../assets/images/Bit.Country.png";
import { FireFilled } from "@ant-design/icons";
import ksmLogo from "../assets/logos/ksm-logo.png";
import { BalanceAnnotation } from "../AccountSelector";
import Swal from "sweetalert2";
import { addressEq } from "@polkadot/util-crypto";

export default function SubstrateAdaptor(props) {
  const sudoAccounts = [
    "ALICE",
    "ALICE_STASH",
    "BOB",
    "BOB_STASH",
    "CHARLIE",
    "DAVE",
    "EVE",
    "FERDIE",
  ];
  const {
    accountAddress,
    setAccountAddress,
    accountBalance,
    setAccountBalance,
    isRewardPage,
    checkRewards,
    isCheckingRewards,
  } = props;

  const { apiState, keyring, keyringState, apiError } = useSubstrate();
  const [showWalletModal, setShowWalletModal] = useState(false);
  const [showError, setBalanceError] = useState(false);
  const { setLoading } = props;

  // Get the list of accounts we possess the private key for
  const keyringOptions = keyring
    ? keyring
        .getPairs()
        .map((account) => ({
          key: account.address,
          value: account.address,
          text: account.meta.name.toUpperCase(),
          icon: "user",
        }))
        .filter((account) => sudoAccounts.includes(account.text) != true)
    : [];

  const initialAddress =
    keyringOptions.length > 0 ? keyringOptions[0].value : "";

  // Set the initial address
  useEffect(() => {
    props.setAccountAddress(initialAddress);
  }, [initialAddress]);

  useEffect(() => {
    if (accountBalance == 0) {
      setBalanceError(true);
    } else {
      setBalanceError(false);
    }
  }, [accountBalance]);
  const onChange = (address) => {
    // Update state with new account address
    console.log("onChange", address);
    props.setAccountAddress(address);
  };

  return (
    <>
      <Row
        className="text-input-wrapper balance-display-wrapper"
        align="bottom"
      >
        <Col span={11}>
          <label style={{ visibility: isRewardPage ? "hidden" : "visible" }}>
            Your Balance
          </label>
        </Col>
        <Col span={11} style={{ zIndex: "1" }} className="align-right">
          <span
            className="connect-button"
            onClick={() => {
              if (keyringOptions.length > 0) {
                setShowWalletModal(true);
              } else {
                Swal.fire({
                  title: "No Polkadot Wallet detected!",
                  html: `Please install <a target="_blank" href="https://polkadot.js.org/extension/">PolkadotJS Extension</a> or connect your wallet to this app`,
                  icon: "info",
                });
              }
            }}
          >
            {accountAddress ? (
              <span>Switch Account</span>
            ) : (
              <span>Connect</span>
            )}
          </span>
        </Col>
      </Row>
      <Row
        className="text-input-wrapper balance-display-wrapper"
        align="bottom"
      >
        <Col span={12}>
          <img src={ksmLogo} className="token-logo" />

          <span style={{ fontSize: "20px" }}>
            {accountAddress === undefined
              ? ""
              : accountAddress === null
              ? "-"
              : `${accountAddress.substring(
                  0,
                  10
                )}...${accountAddress.substring(accountAddress.length - 10)}`}
          </span>
        </Col>
        <Col span={10} style={{ textAlign: "right" }}>
          {isRewardPage ? (
            <div className="button-section" style={{ textAlign: "right" }}>
              <Button
                className="primary-button"
                type="primary"
                title="Next"
                style={{ maxWidth: "50%", marginBottom: "2rem", zIndex: "1" }}
                onClick={() => checkRewards()}
                loading={isCheckingRewards}
              >
                Check
              </Button>
            </div>
          ) : (
            <span className="contribution-balance">
              <BalanceAnnotation
                accountSelected={accountAddress}
                accountBalance={accountBalance}
                setAccountBalance={setAccountBalance}
              />
            </span>
          )}
        </Col>
      </Row>
      <Row
        className="text-input-wrapper balance-display-wrapper"
        align="bottom"
        style={{ visibility: isRewardPage ? "hidden" : "visible" }}
      >
        <Col span={22} style={{ textAlign: "right", zIndex: "1" }}>
          <span>
            *You need to have a minimum of 0.1 KSM
            <br />
            <a
              href={`${window.origin}/#how-to-participate`}
              target="_blank"
              className="how-to-participate-link"
            >
              {" "}
              Learn how to contribute
            </a>
          </span>
        </Col>
      </Row>
      <Modal
        visible={showWalletModal}
        onCancel={() => setShowWalletModal(false)}
        onOk={() => setShowWalletModal(false)}
        footer={null}
        className="scrollable-modal"
      >
        <h2>Select Wallet</h2>
        <div>
          {keyringOptions.map((account, i) => (
            <div
              key={i}
              className="wallet-button substrate-wallet"
              onClick={() => {
                onChange(account.key);
                setShowWalletModal(false);
              }}
            >
              <div>
                <Row>
                  <Col span={22}>
                    <h5>{account.text}</h5>
                    <div>
                      <span>{account.key}</span>
                    </div>
                  </Col>
                  <Col span={2} className="connected">
                    {accountAddress === account.key ? <CheckOutlined /> : null}
                  </Col>
                </Row>
              </div>
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
}
