import React, { useState, createRef, useEffect, useCallback } from "react";
import { useLocation } from "@reach/router";
import { useSubstrate } from "./substrate-lib";
import {
  Col,
  Input,
  Row,
  Spin,
  Table,
  AutoComplete,
  Tooltip,
  Button,
} from "antd";
import "antd/dist/antd.css";
import { ToastProvider, useToasts } from "react-toast-notifications";
import { parse } from "query-string";
import {
  ArrowDownOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { fetchAPI } from "./utils/fetchUtil";
import _ from "lodash";
import ContributionDetails from "./ContributionDetails";
import SubstrateAdaptor from "./adaptors/SubstrateAdaptor";
import Swal from "sweetalert2";
import kaos from "./../src/assets/images/kaos.jpg";
import { web3FromSource } from "@polkadot/extension-dapp";
import { u8aToHex, u8aWrapBytes } from "@polkadot/util";
import Logging from "./utils/Logging";

const rewardPerReferral = 0.025;

export default function Contribution(props) {
  const { api, keyring, keyringState, apiState } = useSubstrate();
  const [accountBalance, setAccountBalance] = useState(0);
  const [apiLoading, setApiLoading] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [walletAddress, setWalletAddress] = useState(null);
  const [reward, setReward] = useState(null);
  const [txHash, setTxHash] = useState("");
  const [isClaimed, setIsClaimed] = useState(null);
  const [hasDistributed, setHasDistributed] = useState(null);
  const [accountPair, setAccountPair] = useState(null);
  const [signature, setSignature] = useState("");
  const [rewardSchedule, setRewardSchedule] = useState(null);

  const loadingIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;
  const loader = (text) => (
    <Spin className="loader" indicator={loadingIcon} tip={text} />
  );

  useEffect(() => {
    if (apiState === "ERROR") {
      showMessage("Error while connecting to blockchain");
    }

    if (keyringState !== "READY" || apiState !== "READY") {
      setApiLoading(true);
    } else if (keyringState === "READY" && apiState === "READY") {
      setApiLoading(false);
    }
  }, [apiState, keyringState]);

  const checkRewards = async () => {
    // Fetch contribution data
    setIsLoading(true);
    const response = await fetchAPI(`/rewards/${walletAddress}`);
    if (response) {
      setReward(response.crowdLoanReward);
      setTxHash(response.txHash);
      setRewardSchedule(response.rewardSchedule);
      setIsClaimed(response.isClaimed);
      setHasDistributed(response.hasDistributed);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const getFromAcct = useCallback(async () => {
    if (!accountPair) {
      return;
    }

    const {
      address,
      meta: { source, isInjected },
    } = accountPair;
    let fromAcct;

    // signer is from Polkadot-js browser extension
    if (isInjected) {
      const injected = await web3FromSource(source);
      fromAcct = address;
      api.setSigner(injected.signer);
    } else {
      fromAcct = accountPair;
    }

    return fromAcct;
  }, [accountPair, api]);

  const calculateReward = useCallback(async (walletAddress) => {
    const response = await fetchAPI(`/rewards/${walletAddress}`);
    if (response) {
      setReward(response.crowdLoanReward);
      setTxHash(response.txHash);
      setRewardSchedule(response.rewardSchedule);
      setIsClaimed(response.isClaimed);
      setHasDistributed(response.hasDistributed);
      return response.crowdLoanReward;
    }
  }, []);

  const claimToken = useCallback(async () => {
    console.log("claimToken");
  }, []);

  const signToVerify = useCallback(async () => {
    if (accountPair) {
      setIsLoading(true);
      const {
        address,
        meta: { source, isInjected },
      } = accountPair;
      const wrappedDataWithBytes = u8aWrapBytes(
        "I hereby agree to the Bit.Country terms and conditions set at https://ksmcrowdloan.bit.country/terms"
      );

      if (isInjected) {
        web3FromSource(source)
          .catch(() => null)
          .then((injected) => {
            injected.signer
              .signRaw({
                address: address,
                data: u8aToHex(wrappedDataWithBytes),
                type: "bytes",
              })
              .then(({ signature }) => {
                Logging.TransactionLog({
                  name: "Submit signature for claiming process",
                  properties: {
                    address: address,
                    signature: signature,
                  },
                });

                fetchAPI(`/claim/${address}/${signature}`)
                  .then((response) => {
                    if (!response.isValid && response.message) {
                      Swal.fire(
                        "Failed to claim crowdloan reward",
                        response.message,
                        "error"
                      );

                      setIsLoading(false);
                      return;
                    }

                    if (response.isValid) {
                      Logging.TransactionLog({
                        name: "Claim sucessful",
                        properties: {
                          response: response.isValid,
                          signature: signature,
                          address: address,
                        },
                      });
                      Swal.fire(
                        "NEER Token Claim Successful!",
                        "Your crowdloan token reward has been scheduled to distribute. You can view your distribution reciept in this page when token distributed. Please allow 48 hours for token to arrive in your wallet.",
                        "success"
                      );
                      setIsLoading(false);
                      setIsClaimed(true);
                    }

                    setIsLoading(false);
                  })
                  .catch((err) => {
                    Logging.TransactionLog({
                      name: "Error when submitting signature verification",
                      properties: {
                        error: err,
                        address: address,
                        signature: signature,
                      },
                    });

                    Swal.fire(
                      "Signature Verification failed",
                      "Signature is invalid",
                      "error"
                    );
                    setIsLoading(false);
                  });
              })
              .catch((error) => {
                Logging.TransactionLog({
                  name: "Error when creating signature",
                  properties: {
                    error: error,
                    address: address,
                  },
                });

                setIsLoading(false);
              });
          });
      }
    }
  }, [api, getFromAcct, accountPair]);

  const showClaimTokenConfirmation = () => {
    Swal.fire({
      title: "Claim NEER Token confirmation",
      icon: "info",
      html: `Before claiming NEER token, you will need to read and agree to our <a href="/terms" target="_blank">Terms and Conditions</a>`,
      showCancelButton: true,
      confirmButtonText: "Agree & Claim",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await signToVerify();
      }
    });
  };

  // Attempt recalculation if values change
  useEffect(() => {
    calculateReward(walletAddress);
    if (walletAddress) {
      setAccountPair(keyring.getPair(walletAddress));
    }
  }, [walletAddress]);

  const showKaosLandModal = (landUnit) => {
    Swal.fire({
      title: "What is Kaosland?",
      icon: "info",
      html:
        `${
          reward?.landGift.landUnit > 0
            ? `<p>Great news! You have received ${landUnit} land units for Kaosland metaverse!<p/>`
            : ""
        }` +
        "<p>Kaosland metaverse will be the very first community owned, partially treasury owned metaverse (and supported by the treasury), with an initial ~26,000 stakeholders, and taking up ~30% of the total land block supply on Pioneer. Very likely, it will be the largest metaverse on the Bit.Country Pioneer Network.<p/>" +
        `${
          reward?.landGift.landUnit > 0
            ? `<a
        class="share-twitter-link"
        target="__blank"
        href="https://twitter.com/intent/retweet?tweet_id=1473403480559665154"
      >
        Retweet your excitement about land ownership in Kaosland
        </a>`
            : ""
        }` +
        `<img src=${kaos} class="kaos-image"/>` +
        `<br/>`,
    });
  };

  return (
    <>
      {apiLoading == true ? (
        loader("Getting ready...")
      ) : (
        <>
          <Col span={22} className="crowdloan-container">
            <Col span={24} className="crowdloan-body fade-in">
              <Spin spinning={isLoading} tip="Loading, please wait">
                <Row className="bridge-container">
                  <Col span={24}>
                    <div className="to-output-container">
                      <Row className="chain-out">
                        <SubstrateAdaptor
                          setAccountAddress={setWalletAddress}
                          accountAddress={walletAddress}
                          accountBalance={accountBalance}
                          setAccountBalance={setAccountBalance}
                          isRewardPage={true}
                          checkRewards={checkRewards}
                          isCheckingRewards={isLoading}
                        />
                      </Row>
                    </div>
                  </Col>{" "}
                  <Col span={24}>
                    <>
                      {reward?.contributionAmount === 0 ? (
                        <Col
                          span={24}
                          className="centered-container"
                          style={{ marginTop: 25 }}
                        >
                          <h2>
                            No contribution found in this wallet address&nbsp;
                          </h2>
                        </Col>
                      ) : (
                        <>
                          <Col
                            span={24}
                            className="centered-container"
                            style={{ marginTop: 25 }}
                          >
                            <ul className="reward-breakdown">
                              <li className="reward-item">
                                <div>
                                  <h2>KSM locked amount&nbsp;</h2>
                                </div>
                                <div>
                                  <span className="text-color-3">
                                    {reward?.contributionAmount} $KSM
                                  </span>
                                </div>
                              </li>
                            </ul>
                          </Col>
                          <Col
                            span={24}
                            className="centered-container"
                            style={{ marginTop: 25 }}
                          >
                            <ul className="reward-breakdown">
                              <h2>Reward breakdown&nbsp;</h2>
                              <li className="reward-item">
                                <div>
                                  <span className="text-color-2">
                                    Base reward
                                  </span>
                                  &nbsp;
                                  <Tooltip
                                    placement="topLeft"
                                    title={
                                      "The base minimum $NEER you receive per KSM (1 KSM : 72 $NEER)"
                                    }
                                  >
                                    <InfoCircleOutlined />
                                  </Tooltip>
                                </div>
                                <div>
                                  <span className="text-color-3">
                                    {reward?.baseReward} $NEER
                                  </span>
                                </div>
                              </li>
                              <li className="reward-item">
                                <div>
                                  <span className="text-color-2">
                                    Early contribution bonus
                                  </span>
                                  &nbsp;
                                  <Tooltip
                                    placement="topLeft"
                                    title={`Extra 10% for the first 1,000 contributions. \n\n* your contribution must be in the first 1,000 finalized transactions to qualify for the bonus`}
                                  >
                                    <InfoCircleOutlined />
                                  </Tooltip>
                                </div>
                                <div>
                                  <span className="text-color-3">
                                    {reward?.earlyContribution.toFixed(2)} $NEER
                                  </span>
                                </div>
                              </li>
                              <li className="reward-item">
                                <div>
                                  <span className="text-color-2">
                                    Referral code bonus
                                  </span>
                                  &nbsp;
                                  <Tooltip
                                    placement="topLeft"
                                    title={`If you used a Referral Code, you and your friend both receive an extra 2.5% reward.\n\nReferral Received: ${
                                      reward
                                        ? reward.referralReceivedReward.toFixed(
                                            2
                                          )
                                        : 0
                                    } \n* from others using your code
                                    \nReferral Used: ${
                                      reward
                                        ? reward?.referralUsedReward.toFixed(2)
                                        : 0
                                    }\n * from you using another's code
                                    `}
                                  >
                                    <InfoCircleOutlined />
                                  </Tooltip>
                                </div>
                                <div>
                                  <span className="text-color-3">
                                    {reward?.referralReward.toFixed(2)} $NEER{" "}
                                  </span>
                                </div>
                              </li>
                              <li className="reward-item">
                                <div>
                                  <span className="text-color-2">
                                    Additional Land Gift
                                  </span>
                                  &nbsp;
                                  <Tooltip
                                    placement="topLeft"
                                    title={`Additional Land Gift as recognition of your contribution.`}
                                  >
                                    <InfoCircleOutlined />
                                  </Tooltip>
                                </div>
                                <div>
                                  <span className="text-color-3">
                                    {!reward?.landGift.landUnit === 0
                                      ? "Not qualified"
                                      : `${reward?.landGift.landUnit} land units in`}{" "}
                                    <a
                                      style={{
                                        textDecoration: "underline",
                                        fontWeight: 800,
                                      }}
                                      onClick={() =>
                                        showKaosLandModal(
                                          reward?.landGift.landUnit
                                        )
                                      }
                                    >
                                      Kaosland
                                    </a>
                                  </span>
                                </div>
                              </li>
                              <li className="reward-item">
                                <div>
                                  <span className="text-color-2">
                                    Pioneer Badge NFT
                                  </span>
                                  &nbsp;
                                  <Tooltip
                                    placement="topLeft"
                                    title={`Claimable NFT Badge that you can show on your avatar. It will show your ranking by Total Contribution and Contribution Time. \nPlease note: Contribution Time ranked by contributors, not contribution. Early contribution reward only for the first 1000 finalized transactions.`}
                                  >
                                    <InfoCircleOutlined />
                                  </Tooltip>
                                </div>
                                <div>
                                  <span className="text-color-3">
                                    {!reward?.landGift ? (
                                      "Not qualified"
                                    ) : (
                                      <span>
                                        {`No. ${reward?.landGift.contributionRank} ranked by Total Contribution`}{" "}
                                        <br />
                                        {`No. ${reward?.landGift.timeRank} ranked by Contribution Time`}{" "}
                                      </span>
                                    )}
                                  </span>
                                </div>
                              </li>
                            </ul>
                          </Col>
                          <Col span={24} className="centered-container">
                            <div className="reward-wrapper">
                              <ul className="reward-breakdown">
                                <li className="reward-schedule">
                                  <div>
                                    <span className="text-color-2">
                                      Total Reward
                                    </span>
                                    &nbsp;
                                    <Tooltip
                                      placement="topLeft"
                                      title={"The total receivable reward"}
                                    >
                                      <InfoCircleOutlined />
                                    </Tooltip>
                                  </div>
                                  <div>
                                    <span className="text-color-3">
                                      {reward?.totalReward.toFixed(2)} $NEER
                                    </span>
                                  </div>
                                </li>
                                <li className="reward-item">
                                  <div>
                                    <span className="text-color-2">
                                      {rewardSchedule?.isUnderMinimum
                                        ? "Full release"
                                        : "Initial Release"}
                                    </span>
                                    &nbsp;
                                    <Tooltip
                                      placement="topLeft"
                                      title={
                                        rewardSchedule?.isUnderMinimum
                                          ? "The transferrable NEER token that will be unlocked when you claimed, that is included the vesting balance is less than 10 NEER."
                                          : "The initial unlocked (transferrable) NEER token available upon claiming."
                                      }
                                    >
                                      <InfoCircleOutlined />
                                    </Tooltip>
                                  </div>
                                  <div>
                                    <span className="text-color-3">
                                      {rewardSchedule?.initialRelease} $NEER
                                    </span>
                                  </div>
                                </li>
                                <li className="reward-item">
                                  <div>
                                    <span className="text-color-2">
                                      Vested Release
                                    </span>
                                    &nbsp;
                                    <Tooltip
                                      placement="topLeft"
                                      title={`The vested balance that will gradually release over the lease period starting from TGE day. Minimum vesting is 10 NEER. If your vesting balance is less than 10 NEER, vesting schedule is not applied`}
                                    >
                                      <InfoCircleOutlined />
                                    </Tooltip>
                                  </div>
                                  <div>
                                    {rewardSchedule?.isUnderMinimum ? (
                                      "N/A - Vesting under minimum 10 NEER"
                                    ) : (
                                      <span className="text-color-3">
                                        {rewardSchedule?.vestedRelease} $NEER
                                      </span>
                                    )}
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </Col>{" "}
                        </>
                      )}
                    </>
                  </Col>
                  {reward?.totalReward > 0 ? (
                    <Col span={24}>
                      <div
                        className="button-section"
                        style={{ textAlign: "center" }}
                      >
                        {isClaimed && !hasDistributed ? (
                          <Button
                            className="primary-button"
                            type="primary"
                            title="Next"
                            style={{
                              maxWidth: "70%",
                              marginBottom: "2em",
                              marginLeft: "2em",
                              zIndex: "1",
                            }}
                            onClick={() => checkRewards()}
                          >
                            Scheduled for Distribution
                          </Button>
                        ) : null}
                        {isClaimed && hasDistributed && txHash !== "" ? (
                          <div>
                            <Button
                              className="primary-button"
                              type="primary"
                              title="Next"
                              style={{
                                maxWidth: "50%",
                                marginBottom: "2em",
                                zIndex: "1",
                              }}
                              onClick={() =>
                                window.open(
                                  `https://polkadot.js.org/apps/?rpc=wss%3A%2F%2Fpioneer-1-rpc.bit.country#/explorer/query/${txHash}`
                                )
                              }
                            >
                              View distribution tx reciept
                            </Button>
                            <Button
                              className="primary-button"
                              type="primary"
                              title="Next"
                              style={{
                                maxWidth: "20%",
                                marginBottom: "2em",
                                marginLeft: "2em",
                                zIndex: "1",
                              }}
                              onClick={() =>
                                window.open(
                                  `https://polkadot.js.org/apps/?rpc=wss%3A%2F%2Fpioneer-1-rpc.bit.country#/accounts`
                                )
                              }
                            >
                              Check account
                            </Button>
                          </div>
                        ) : null}
                        {!isClaimed ? (
                          <Button
                            className="primary-button"
                            type="primary"
                            title="Next"
                            style={{
                              maxWidth: "50%",
                              marginBottom: "1em",
                              zIndex: "1",
                            }}
                            onClick={() => showClaimTokenConfirmation()}
                          >
                            Claim NEER Token
                          </Button>
                        ) : null}
                      </div>
                      <div style={{ marginBottom: "2em" }}>
                        <i>
                          For now, Ledger hardware wallets are not supported by
                          Bit.Country Pioneer Network, please do not attempt to
                          claim NEER tokens with a Ledger at this time - we will
                          support these claims soon, please check back here for
                          updates.
                        </i>
                      </div>
                    </Col>
                  ) : null}
                </Row>
                {reward?.totalReward > 0 ? (
                  <Row className="segment-container">
                    <Col span={24}>
                      <h2 className="special-reward-header">Special Reward</h2>
                      <div className="special-reward-container">
                        <div>
                          <h3 className="special-reward-title">
                            Special Land Blocks Pool
                          </h3>
                          <p>
                            The treasury will sponsor an initial 1,000 land
                            blocks (10% of Total Land Supply) for Kaosland
                            landowners to claim once they meet the following
                            criteria:
                            <ul>
                              <li className="list-no-style">
                                1. User’s avatar reaches level 10
                              </li>
                              <li className="list-no-style">
                                2. And user has at least one land unit in
                                Kaosland that reaches level 10. <br />
                                The land block is used to launch their own
                                individual metaverse with their own map,
                                governance, and world.
                              </li>
                            </ul>
                          </p>
                        </div>
                        <div>
                          <h3 className="special-reward-title">
                            Special NFT for Metaverse Odyssey Event Contributor
                          </h3>
                          <p>
                            A BIT Mining Booster NFT can be claimed if you
                            participated in this event. This NFT will boost your
                            mining power by 15% for 14 days from when you
                            consume it. This will be available on the platform,
                            more details to be released later.
                            <br />* if you qualify to receive both BIT Mining
                            Booster NFTs, they don’t stack. You should use them
                            one by one. E.g. after the first one’s duration has
                            expired, then use the second one.
                          </p>
                        </div>
                        <div>
                          <h3 className="special-reward-title">
                            Special NFT for Pioneer Crowdloan’s First 2,000
                            Contributors
                          </h3>
                          <p>
                            A BIT Mining Booster NFT can be claimed if you
                            participated in this event. This NFT will boost your
                            mining power by 15% over 14 days from when you
                            consume it. This will be available on the platform
                            and claimable, more details to be released later.{" "}
                            <br />* if you qualify to receive both BIT Mining
                            Booster NFTs, they don’t stack. You should use them
                            one by one. E.g. after the first one’s duration has
                            expired, then use the second one.
                          </p>
                        </div>
                        <div>
                          <h3 className="special-reward-title">
                            Special Land for Metaverse Evangelists
                          </h3>
                          <p>
                            Bit.Country metaverse evangelists will receive an
                            additional 4 land units as recognition of their
                            effort helping out the community and creating a
                            great vibe in Discord and Telegram.
                          </p>
                        </div>
                        <div>
                          <h3 className="special-reward-title">
                            Special NFT Giveaway from Our Metaverse Building
                            Partner MetaDojo
                          </h3>
                          <p>
                            MetaDojo offers ready-built and affordable metaverse
                            premises that can be seamlessly deployed to any land
                            on the Bit.Country & Metaverse.Network platform. To
                            celebrate this well-deserved land distribution and
                            honor early community supporters, they are launching
                            a special NFT airdrop event that is exclusive to
                            Bit.Country whitelisted members. Go{" "}
                            <a
                              target="_blank"
                              href="https://metadojo.io/BCairdrop"
                            >
                              <b>here</b>
                            </a>{" "}
                            to find out more on how to participate and claim
                            their very first ‘Groundbreaking Badge’ which grants
                            you early access to their Dojos and token sale
                            planned for Q1 2022.
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                ) : null}
              </Spin>
            </Col>
          </Col>
        </>
      )}
    </>
  );
}
