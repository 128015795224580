import React from "react";
import { Button, Col, Row, Table } from "antd";
import "antd/dist/antd.css";
import { ToastProvider, useToasts } from "react-toast-notifications";

export default function LandReward() {
  return (
    <ToastProvider autoDismiss>
      <div className="title-header">
        <h2>Additional KaosLand Land Gift</h2>
      </div>
      <div className="title-header">
        <h3>Coming Soon</h3>
      </div>
    </ToastProvider>
  );
}
