import React from "react";
import { TwitterOutlined, FacebookOutlined, LinkedinOutlined, MediumOutlined, GithubOutlined } from "@ant-design/icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTelegram, faDiscord } from "@fortawesome/free-brands-svg-icons";
import { faSearch, faSlidersH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(faSearch, faSlidersH, faTelegram, faDiscord);

export default function SocialLinks() {
  return (
    <>
      <a href="https://twitter.com/bitdotcountry  " target="_blank" rel="noopener noreferrer">
        <TwitterOutlined />
      </a>      
      <a href="https://discord.gg/PaMAXZZ59N" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={[ "fab", "discord" ]} />
      </a>
      <a href="https://t.me/BitCountryOfficialTG" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={[ "fab", "telegram" ]} />
      </a>
    </>
  );
}