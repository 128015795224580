export async function fetchManual(
  apiRoot = process.env.REACT_APP_API_BASE_URL,
  endpoint,
  method = "get",
  body = null,
  headers = {},
  signal = null
) {
  try {
    const url = apiRoot + endpoint;

    let options = {
      method,
      headers: {
        ...headers,
      },
      signal,
    };

    if (body) {
      options.body = body;
    }

    const response = await fetch(url, options);

    if (!response.ok) {
      const responseBody = await response.text();

      try {
        const json = await JSON.parse(responseBody);

        if (response.status == 404 && json.isSuccess == false) {
          return {
            status: 404,
            json: json,
          };
        }

        console.error(
          new Error(
            `Error with request\nURL: ${url}\nOptions: ${JSON.stringify(
              options
            )}\nResponse: ${JSON.stringify(
              response
            )}\nResponse body: ${responseBody}`
          ),
          url,
          options,
          response
        );

        return json;
      } catch (error) {
        console.error(
          new Error(
            `Error with request\nURL: ${url}\nOptions: ${JSON.stringify(
              options
            )}\nResponse: ${JSON.stringify(
              response
            )}\nResponse body: ${responseBody}`
          ),
          url,
          options,
          response
        );

        return {
          status: response.status,
          json: null,
        };
      }
    }

    const responseBody = await response.json();

    return responseBody;
  } catch (error) {
    console.error(error);

    return null;
  }
}

export async function fetchAPI(endpoint, method = "get", body = null) {
  const newBody = body ? JSON.stringify(body) : null;
  const headers = {
    "Content-Type": "application/json",
  };

  return fetchManual(
    process.env.REACT_APP_API_BASE_URL,
    endpoint,
    method,
    newBody,
    headers
  );
}
